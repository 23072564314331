import { Jumbotron } from "react-bootstrap";

function Contact() {
  return (
    <Jumbotron>
      <h1 className="header">Welcome To React-Bootstrap</h1>
    </Jumbotron>
  );
}

export default Contact;