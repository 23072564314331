import {
  Container,
  Jumbotron
} from 'react-bootstrap';
import './App.css';
import Contact from './Contact';

function App() {
  return (
    <div className="bg">
      <Container className="p-3">
        <Jumbotron>
          <h1 className="header">Welcome To React-Bootstrap</h1>
        </Jumbotron>
      </Container>
      <Contact></Contact>
    </div>
  );
}

export default App;
